<template>
  <div style="margin: auto;display: flex;justify-content: center;align-items: center;flex-direction: column;">
    <Timer :seconds="timer.seconds" :minutes="timer.minutes" :hours="timer.hours" :days="timer.days" />
    <div class="mx-auto">
      <Button type="button" @click="timer.start()">DÉMARRER</Button>
      <Button type="button" @click="pause()">PAUSE</Button>
      <Button type="button" @click="resume()">REPRENDRE</Button>
      <Button type="button" @click="reload()">RÉINITIALISER</Button>
    </div>
  </div>
</template>

<script setup>
import { useTimer } from 'vue-timer-hook';
import Timer from "./Timer.vue"
import { defineProps } from 'vue'
import Button from "./button.vue"
import { watchEffect, onMounted } from "vue";

const props = defineProps({
  expiryTimestamp: {
    type: Number,
    required: true
  },
  resetTimer: {
    type: Function,
  },
  pauseTimer: {
    type: Function,
  },
  resumeTimer: {
    type: Function,
  },
});

const timer = useTimer(props.expiryTimestamp, true)


if(localStorage.pause){
  if (JSON.parse(localStorage.pause) == true) {
    pause();
  }
}

function pause() {
  timer.pause()
  props.pauseTimer()
  localStorage.pause = true
}


function resume() {
  timer.resume()
  props.resumeTimer()
  localStorage.pause = false

}

watchEffect(() => {
  console.warn(timer.days)
})

const reload = () => {
  // Restarts to 10 minutes timer
  const time = new Date();
  time.setSeconds(time.getSeconds() + 900);
  timer.restart(time);
  props.resetTimer()
  localStorage.finished = false
  localStorage.reset = true

}

onMounted(() => {
  watchEffect(async () => {
    if (timer.isExpired.value) {
      localStorage.finished = true
    }
  })
})
</script>