<template>
  <v-layout>

    <v-navigation-drawer color="teal-darken-4" v-model="drawer" :rail="rail" permanent @click="rail = false">
      <v-list-item prepend-avatar="https://ui-avatars.com/api/?background=random&name=ROQ" title=""></v-list-item>

      <v-divider></v-divider>

      <v-list density="compact" nav>
        <v-list-item prepend-icon="mdi-view-dashboard" title="Accueil" value="home"
          @click="goPage('home')"></v-list-item>
        <v-list-item prepend-icon="mdi-view-dashboard" title="Mes actions" value="myevents"
          @click="goPage('myevents')"></v-list-item>
        <v-list-item prepend-icon="mdi-card" title="Actions (Crees par)" value="createdBy"
          @click="goPage('eventsPerDepCre')"></v-list-item>
        <v-list-item prepend-icon="mdi-forum" title="Calendrier" value="" @click="goPage('about')"></v-list-item>
        <v-list-item prepend-icon="mdi-phone" title="Visioconférence " value="meeting"
          @click="goPage('meeting')"></v-list-item>
        <!-- <v-list-item prepend-icon="mdi-grid" title="Grid " value="meeting" @click="goPage('grid')"></v-list-item> -->
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-app-bar color="teal-darken-4" image="https://picsum.photos/1920/90080?random">
        <template v-slot:image>
          <v-img gradient="to top right, rgba(19,84,122,.8), rgba(128,208,199,.8)"></v-img>
        </template>

        <template v-slot:prepend>
          <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        </template>

        <v-app-bar-title>ROQ</v-app-bar-title>

        <v-spacer></v-spacer>

        <!-- <v-btn icon @click="dialog = true">
          <v-icon>mdi-plus</v-icon>
        </v-btn> -->

        <v-btn icon @click="refreshPage">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <v-btn icon @click="toggleTheme">
          <v-icon>mdi-brightness-4</v-icon>
        </v-btn>
      </v-app-bar>
      <!-- <v-alert text="Added successfully" type="success"></v-alert> -->
      <!-- v-show="this.$route.params.id == 1 || this.$route.params.id == 242" -->
      <v-sheet class="d-flex justify-center pa-3" elevation="2" rounded >
        <UseTimerDemo :expiryTimestamp="dateTimer" :resetTimer="resetTimer" :resumeTimer="resumeTimer"
          :pauseTimer="pauseTimer" />
      </v-sheet>

      <router-view />

    </v-main>

    <v-dialog v-model="dialog" width="600">
      <v-card max-width="800" prepend-icon="mdi-plus" text="Priere de saisir ....." title="Information...">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12" sm="6">
              <v-text-field label="Titre" required v-model="event.title"></v-text-field>
            </v-col>
          </v-row>

          <v-textarea label="Description" v-model="event.description"></v-textarea>

          <v-row>
            <v-col cols="6" md="6" sm="6">
              <v-text-field type="datetime-local" label="Date debut" v-model="event.startDate"></v-text-field>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-text-field type="datetime-local" label="Date fin" v-model="event.endDate"></v-text-field>
            </v-col>
          </v-row>

          <v-select :items="departments" item-title="code" label="Direction Emettrice" item-value="id"
            v-model="event.departmentEmitter" persistent-hint>
            <!-- <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :subtitle="item.raw.code">
              </v-list-item>
            </template> -->
          </v-select>

          <v-select :items="departments" item-title="code" label="Direction" item-value="id" v-model="event.departments"
            multiple persistent-hint>
            <!-- <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :subtitle="item.raw.code">
              </v-list-item>
            </template> -->
          </v-select>

          <v-select :items="flags" item-title="name" label="Drapeau" item-value="id" v-model="event.flag">
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :subtitle="item.raw.code" append-icon="mdi-flag" :base-color="item.raw.color">
              </v-list-item>
            </template>
          </v-select>

          <small class="text-caption text-medium-emphasis">*indicates required field</small>

        </v-card-text>
        <v-card-footer>
          <v-col cols="auto">
            <v-btn block color="teal" @click="save">SAUVEGARDER</v-btn>
          </v-col>
        </v-card-footer>
      </v-card>
    </v-dialog>

  </v-layout>
</template>


<script setup>
import { useTheme } from "vuetify";
import UseTimerDemo from '@/components/TimerComponent/UseTimerDemo.vue'
import moment from 'moment';
import { ref } from 'vue'
import { watchEffect } from "vue";

// var init = new Date().setSeconds(new Date().getSeconds() + 900)

var dateTimer = ref(new Date().setSeconds(new Date().getSeconds() + 900))



if (localStorage.dateTimer) {
  dateTimer = ref(JSON.parse(localStorage.dateTimer))
} else {
  localStorage.dateTimer = new Date().setSeconds(new Date().getSeconds() + 900)
}

function pauseTimer() {
  console.log("pause upper");
  localStorage.dateTimer = dateTimer.value
}

function resumeTimer() {
  console.log("resume upper");
  localStorage.dateTimer = dateTimer.value
}

function resetTimer() {
  localStorage.clear()
  localStorage.dateTimer = new Date().setSeconds(new Date().getSeconds() + 900)
  dateTimer.value = new Date().setSeconds(new Date().getSeconds() + 900)
}


window.onbeforeunload = function (event) {

  if (localStorage.finished) {
    if (JSON.parse(localStorage.finished) == false) {
      localStorage.dateTimer = dateTimer.value

    }
  }

};

watchEffect(() => {
  console.log(dateTimer.value)
  if (localStorage.finished) {
    console.log(JSON.parse(localStorage.finished) != true);
  }
})



const theme = useTheme();
function toggleTheme() {
  theme.global.name.value = theme.global.current.value.dark ? "light" : "dark";
}
</script>

<script>
// import MeetingCompo from "./components/MeetingCompo.vue";
import axios from "axios";
import { flags } from './utils';

export default {
  components: { UseTimerDemo },
  data() {
    return {
      stateId: null,
      dialog: false,
      drawer: false,
      time: null,
      picker: new Date(Date.now()),
      rail: true,
      userId: null,
      selectedFlag: null,
      departments: [],
      flags: flags,
      event: {
        title: "",
        description: "",
        startDate: "",
        endDate: "",
        department: "",
        departmentId: "",
        departmentEmitter: null,
        flag: "",

      }
    };
  },
  methods: {
    //.replace(/T.*$/, '')
    save() {
      var userId = this.$store.state.id;
      var body = {
        "subject": this.event.title,
        "typeSelect": "5",
        "userId": userId,
        "createdBy": { "id": userId },
        // "createdOn" : new Date().toLocaleString().slice(0,16),
        "startDateTime": this.event.startDate.replace(/T/, ' '),
        "endDateTime": this.event.endDate.replace(/T/, ' '),
        "description": this.event.description,
        "departmentEmitter": this.event.departmentEmitter == null ? null : { "id": this.event.departmentEmitter },
        "departments": this.event.departments.map(e => ({ "id": e })),
        "flag": this.event.flag,
        "dtype": "Event"
      };

      // alert(this.event.departmentEmitter,body)

      axios.post("https://roqserver.sophal.net/api/events", body).then(() => {
        // console.log(response);
        this.dialog = false;
        window.location.reload();
      }).catch(err => console.log(err));

    },
    refreshPage() {
      window.location.reload();
    },
    goPage(routeName) {
      this.$router.replace({ name: routeName, params: { id: this.$route.params.id } });
    },
  },
  mounted() {

    var _self = this;
    this.userId = this.$route.params.id
    this.$store.commit("setId", this.$route.params.id);
    this.stateId = this.$store.state.id

    this.event.startDate = moment().format("YYYY-MM-DD hh:mm");
    this.event.endDate = moment().add(1, 'day').format("YYYY-MM-DD hh:mm");

    axios.get("https://roqserver.sophal.net/api/departments").then((response) => {
      response["data"].forEach(element => {
        _self.departments.push({
          id: element["id"],
          code: element["code"],
          name: element["name"],
        })
      });
    });

  },
  created() {
  },
  computed: {

  },
};
</script>
