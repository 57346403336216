<template>
    <v-layout row wrap>
        <v-card>
            <MeetingCompo class="w-100" />
        </v-card>
    </v-layout>
</template>

<script>
import MeetingCompo from "@/components/MeetingCompo.vue";

export default {
    components: {
        MeetingCompo,
    },
};
</script>