import Vuex from 'vuex';

// import VuexPersistence from 'vuex-persist';
// import localForage from 'localforage';
// const vuexLocal = new VuexPersistence({
//   storage: localForage,
//   asyncStorage: true,
// });



export default new Vuex.Store({
  state: {
    id: null,
    user: null,
    timerShape : new Date().setSeconds(new Date().getSeconds() + 900)  
  },
  getters: {
    getUser(state){
      return state.user;
    }
  },
  mutations: {
    setId(state, id) {
      state.id = id
    },
    setUser(state, user) {
      state.user = user
    }
  },
  actions: {

  },
  modules: {},
  // plugins: [vuexLocal.plugin],

})