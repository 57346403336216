<template>
  <div class='demo-app'>
    <v-card class="ma-3 pa-3">
      <v-row>
        <v-col md="3">
          <div class='demo-app-sidebar'>
            <div class='demo-app-sidebar-section'>
              <!-- <h2>Instructions</h2>
              <ul>
                <li>Select dates and you will be prompted to create a new event</li>
                <li>Drag, drop, and resize events</li>
              </ul> -->
            </div>
            <!-- <div class='demo-app-sidebar-section'>
          <v-switch v-model="calendarOptions.weekends" label="Weekends" />
        </div> -->
            <div class='demo-app-sidebar-section'>

              <v-card>
                <h2 color="teal">Actions ({{ currentEvents.length }})</h2>
                <v-list lines="one">
                  <v-list-item v-for='event in currentEvents' :key='event.id' :title="event.title" subtitle="" border>


                  </v-list-item>
                </v-list>
              </v-card>
            </div>
          </div>
        </v-col>
        <v-col md="9">
          <div class='demo-app-main'>
            <FullCalendar class='demo-app-calendar' :options='calendarOptions'>
              <template v-slot:eventContent='arg'>
                <!-- <i>{{ arg.event.extendedProps.departments[0].code }}</i> || -->
                <b>{{ arg.timeText }}</b>
                <i>{{ arg.event.title.toUpperCase() }}</i> |
                <i>Start :{{ arg.event.start.toISOString().replace(/T.*$/, '') }}</i> ||
                <i>End :{{ arg.event.start.toISOString().replace(/T.*$/, '') }}</i> ||
              </template>
            </FullCalendar>
          </div>
        </v-col>
      </v-row>

    </v-card>


    <v-dialog v-model="dialog" width="600" disabled="true">
      <v-card max-width="800" prepend-icon="mdi-plus" text="Priere de saisir ....." title="Information...">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12" sm="6">
              <v-text-field label="Titre" required v-model="event.title"></v-text-field>
            </v-col>
          </v-row>

          <v-textarea label="Description" v-model="event.description"></v-textarea>

          <v-row>
            <v-col cols="6" md="6" sm="6">
              <v-text-field type="datetime-local" label="Date debut" v-model="event.startDate"></v-text-field>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-text-field type="datetime-local" label="Date fin" v-model="event.endDate"></v-text-field>
            </v-col>
          </v-row>

          <v-select :items="departments" item-title="code" label="Direction Emettrice" item-value="id"
            v-model="event.departmentEmitter" persistent-hint>
            <!-- <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :subtitle="item.raw.code">
              </v-list-item>
            </template> -->
          </v-select>

          <v-select :items="departments" item-title="code" label="Direction" item-value="id" v-model="event.departments"
            multiple persistent-hint>
            <!-- <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :subtitle="item.raw.code">
              </v-list-item>
            </template> -->
          </v-select>

          <v-select :items="flags" item-title="name" label="Flags" item-value="id" v-model="event.flag">
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :subtitle="item.raw.code" append-icon="mdi-flag" :base-color="item.raw.color">
              </v-list-item>
            </template>
          </v-select>

          <!-- <small class="text-caption text-medium-emphasis">*indicates required field</small> -->

        </v-card-text>
        <v-card-footer>
          <v-col cols="auto">
            <!-- <v-btn block color="teal" @click="saveOrUpdate(editMode, event.id)">Save</v-btn> -->
          </v-col>
        </v-card-footer>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import { defineComponent } from 'vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr';
import esLocale from '@fullcalendar/core/locales/es';


// import { INITIAL_EVENTS } from './event-utils'
import axios from "axios"
import { getRandomColor, flags } from '@/utils'

export default defineComponent({
  components: {
    FullCalendar,
  },
  data() {
    return {
      editMode: false,
      dialog: false,
      selectedEvent: 0,
      calendarOptions: {
        events: [],
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        initialView: 'dayGridMonth',
        initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventChange: this.handleEventChange,
        locales: [esLocale, frLocale],
        locale: 'fr'
        /* you can update a remote database when these fire:
        eventAdd:
        eventRemove:
        */
      },
      currentEvents: [],
      event: {
        title: "",
        description: "",
        startDate: "",
        endDate: "",
        departments: [],
        departmentId: "",
        departmentEmitter: "",
        flag: "",
      },
      flags: flags,
      departments: [],
      calendarApi: null
    }
  },
  methods: {
    resetEventFields() {
      this.event.title = ""
      this.event.description = ""
      this.event.startDate = ""
      this.event.endDate = ""
      this.event.departments = []
      this.event.departmentId = ""
      this.event.flag = ""
      this.event.departmentEmitter = ""
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    handleDateSelect(selectInfo) {
      this.resetEventFields();
      this.dialog = true
      this.event.startDate = selectInfo.startStr + " 00:00";
      this.event.endDate = selectInfo.endStr + " 00:00";
      // let title = prompt('Please enter a new title for your event')
      let calendarApi = selectInfo.view.calendar

      this.calendarApi = calendarApi;

      calendarApi.unselect() // clear date selection

      // if (this.event.title) {
      //   calendarApi.addEvent({
      //     id: 1121,
      //     title,
      //     start: selectInfo.startStr,
      //     end: selectInfo.endStr,
      //     allDay: selectInfo.allDay
      //   })
      // }
    },
    handleEventClick(clickInfo) {
      // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.id}'`)) {
      //   clickInfo.event.remove()
      // }

      var self = this
      axios.get("https://roqserver.sophal.net/api/events/" + clickInfo.event.id).then((response) => {
        var element = response["data"];
        self.event.id = element.id
        self.event.title = element.subject
        self.event.description = element.description
        self.event.startDate = element.startDateTime
        self.event.endDate = element.endDateTime
        self.event.departments = element.departments.map(e => e.id)
        self.event.flag = element.flag
        self.event.departmentEmitter = element.departmentEmitter

        self.editMode = true;
        self.dialog = true

      }).catch(err => console.log(err));

    },
    handleEvents(events) {
      this.currentEvents = events
    },
    handleEventChange(events) {
      this.selectedEvent = events.event.id;

      var startDate = events.event._instance.range.start.toISOString().replace(/T/, ' ').substr(0, 16);
      var endDate = events.event._instance.range.end.toISOString().replace(/T/, ' ').substr(0, 16);
      this.updateDates(this.selectedEvent, startDate, endDate)

    },
    fetchEvents() {
      var _self = this
      var userId = this.$route.params.id
      axios.get("https://roqserver.sophal.net/api/events/user/" + userId).then((response) => {
        var arr = []
        response["data"].forEach(element => {
          arr.push({
            id: element["id"],
            title: element["subject"],
            start: element["startDateTime"],
            end: element["endDateTime"],
            departments: element["departments"] != null ? element["departments"] : "Not found",
            departmentEmitter: element["departmentEmitter"],
            color: getRandomColor(element["flag"]),
            allDay: true
          })
          _self.calendarOptions.events = arr
          _self.currentEvents = arr
        });

      });
    },
    fetchDepartments() {
      var _self = this
      axios.get("https://roqserver.sophal.net/api/departments").then((response) => {
        response["data"].forEach(element => {
          _self.departments.push({
            id: element["id"],
            code: element["code"],
            name: element["name"],
          })
        });

      });
    },
    saveOrUpdate(editMode, id) {
      var userId = this.$store.state.id;
      var body = {
        "subject": this.event.title,
        "typeSelect": "5",
        "userId": userId,
        "createdBy": { "id": userId },
        // "createdOn" : new Date().toLocaleString().slice(0,16),
        "startDateTime": this.event.startDate,
        "endDateTime": this.event.endDate,
        "description": this.event.description,
        "departmentEmitter": { "id": this.event.departmentEmitter },
        "departments": this.event.departments.map(e => ({ "id": e })),
        "flag": this.event.flag,
        "dtype": "Event"
      };

      var _self = this;

      if (!editMode) {
        axios.post("https://roqserver.sophal.net/api/events", body).then((response) => {
          _self.calendarApi.addEvent({
            id: response.id,
            title: _self.event.title,
            start: _self.event.startDate.replace(/T/, ' '),
            end: _self.event.endDate.replace(/T/, ' '),
            allDay: true
          })
          _self.dialog = false;
          _self.$swal("Mise a jour avec succes");
          window.location.reload();

        }).catch(err => console.log(err));
      } else {
        body["id"] = id;
        // body["departments"] = _self.event.departments.map(e => ({ "id": e.id })),

        console.log("*******************************", body);


        axios.put("https://roqserver.sophal.net/api/events", body).then(() => {
          // console.log(response);
          _self.dialog = false;
          _self.$swal("Mise a jour avec succes");
          this.editMode = false;
          window.location.reload();

        }).catch(err => console.log(err));
      }
    },
    updateDates(id, startDate, endDate) {
      var body = {
        "id": id,
        "startDateTime": startDate.replace(/T/, ' '),
        "endDateTime": endDate.replace(/T/, ' '),
      };
      var _self = this;
      axios.patch("https://roqserver.sophal.net/api/events", body).then(() => {
        _self.dialog = false;
        _self.$swal("Mise a jour avec succes");
        this.editMode = false;

      }).catch(err => console.log(err));
    }
  },
  mounted() {

    this.fetchEvents()
    this.fetchDepartments();

  }
})

</script>


<style>
.fc-view-harness {
  border: 1px solid teal;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: teal !important;
  color: white !important;
}

thead {
  background: teal;
  color: white;
  text-transform: uppercase;
}

.fc .fc-button .fc-icon {
  line-height: 1.3rem !important;
}

.fc-toolbar-title {
  text-transform: uppercase;

}
</style>