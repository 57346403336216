<template>
  <v-container fill-height fluid>
    <v-breadcrumbs bg-color="teal" :items="['/Département', depCode]"></v-breadcrumbs>
    <v-text-field label="Filter" v-model="search" @input="searchChange"></v-text-field>

    <v-btn color="success" class="float-end btn-block my-3" block size="large"
      @click="saveMode"><v-icon>mdi-plus</v-icon>Ajouter</v-btn>
    <div class="clearfix"></div>

    <div v-if="loading" class="text-center">
      <v-progress-circular color="primary" indeterminate :size="49"></v-progress-circular>
    </div>
    <div v-else>
      <v-row v-if="actions.length > 0">
        <v-col xl="auto">
          <v-card style="height: 100%" color="grey">
            <v-card-title primary-title bordered>

              <v-list-item prepend-icon="mdi-flag" color="grey" title="GRIS">
                <template v-slot:append>
                  <v-badge color="white" :content="searchGreys.length" inline></v-badge>
                </template>
              </v-list-item>
            </v-card-title>
            <v-card-text>

              <draggable group="texto" class="dragArea list-group w-full" :list="searchGreys" @change="log"
                :disabled="isDisabled">
                <div class="list-group-item bg-gray-300 m-1 p-3 rounded-md text-center cursor-grab" style="color: grey;"
                  v-for="element in searchGreys" :key="element.title">
                  <event-c class="ma-0" :action="element"></event-c>
                </div>
              </draggable>

            </v-card-text>
          </v-card>
        </v-col>

        <v-col xl="auto">
          <v-card style="height: 100%" color="red">
            <v-card-title primary-title>

              <v-list-item prepend-icon="mdi-flag" color="white" title="ROUGE">
                <template v-slot:append>
                  <v-badge color="white" :content="searchReds.length" inline></v-badge>
                </template>
              </v-list-item>
            </v-card-title>
            <v-card-text>

              <draggable group="texto" class="dragArea list-group w-full" :list="searchReds" @change="log"
                :disabled="isDisabled">
                <div class="list-group-item bg-gray-300 m-1 p-3 rounded-md text-center cursor-grab" style="color: red;"
                  v-for="element in searchReds" :key="element.title">
                  <event-c class="ma-0" :action="element"></event-c>

                </div>
              </draggable>

            </v-card-text>
          </v-card>
        </v-col>

        <v-col xl="auto">
          <v-card style="height: 100%" color="orange">
            <v-card-title primary-title>

              <v-list-item prepend-icon="mdi-flag" color="white" title="ORANGE">
                <template v-slot:append>
                  <v-badge color="white" :content="searchOranges.length" inline></v-badge>
                </template>
              </v-list-item>
            </v-card-title>
            <v-card-text>

              <draggable group="texto" class="dragArea list-group w-full" :list="searchOranges" @change="log"
                :disabled="isDisabled">
                <div class="list-group-item bg-gray-300 m-1 p-3 rounded-md text-center cursor-grab"
                  style="color: orange;" v-for="element in searchOranges" :key="element.title">
                  <event-c class="ma-0" :action="element"></event-c>
                </div>
              </draggable>

            </v-card-text>
          </v-card>
        </v-col>

        <v-col xl="auto">
          <v-card style="height: 100%" color="green">
            <v-card-title primary-title>

              <v-list-item prepend-icon="mdi-flag" color="white" title="VERT">
                <template v-slot:append>
                  <v-badge color="white" :content="searchGreens.length" inline></v-badge>
                </template>
              </v-list-item>
            </v-card-title>
            <v-card-text>

              <draggable group="texto" class="dragArea list-group w-full" :list="searchGreens" @change="log"
                :disabled="isDisabled">
                <div class="list-group-item bg-gray-300 m-1 p-3 rounded-md text-center cursor-grab" style="color: grey;"
                  v-for="element in searchGreens" :key="element.title" tag="">
                  <event-c class="ma-0" :action="element"></event-c>
                </div>
              </draggable>

            </v-card-text>
          </v-card>
        </v-col>

        <v-col xl="auto">
          <v-card style="height: 100%" color="indigo">
            <v-card-title primary-title>

              <v-list-item prepend-icon="mdi-flag" color="white" title="PAC">
                <template v-slot:append>
                  <v-badge color="white" :content="searchIndigos.length" inline></v-badge>
                </template>
              </v-list-item>
            </v-card-title>
            <v-card-text>

              <draggable group="texto" class="dragArea list-group w-full" :list="searchIndigos" @change="log"
                :disabled="isDisabled">
                <div class="list-group-item bg-gray-300 m-1 p-3 rounded-md text-center cursor-grab" style="color: indigo;"
                  v-for="element in searchIndigos" :key="element.title" tag="">
                  <event-c class="ma-0" :action="element"></event-c>
                </div>
              </draggable>

            </v-card-text>
          </v-card>
        </v-col>

        <!-- <v-col md="4" sm="12" v-for="action in actions" :key="action.id">
          <event-c class="ma-0" :action="action" :flags="flags"></event-c>
        </v-col> -->
      </v-row>
      <div v-else class="text-center pa-10">
        Pas de données disponibles
      </div>
    </div>

    <v-dialog v-model="dialog" width="600">
      <v-card max-width="800" prepend-icon="mdi-plus" text="Priere de saisir ....." title="Information...">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="12" sm="6">
              <v-text-field label="Titre" required v-model="event.title"></v-text-field>
            </v-col>
          </v-row>

          <v-textarea label="Description" v-model="event.description"></v-textarea>

          <v-row>
            <v-col cols="6" md="6" sm="6">
              <v-text-field type="datetime-local" label="Date debut" v-model="event.startDate"></v-text-field>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-text-field type="datetime-local" label="Date fin" v-model="event.endDate"></v-text-field>
            </v-col>
          </v-row>

          <v-select :items="departments" clearable item-title="code" label="Direction Emettrice" item-value="id"
            v-model="event.departmentEmitter" persistent-hint>
            <!-- <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :subtitle="item.raw.code">
              </v-list-item>
            </template> -->
          </v-select>

          <v-select :items="departments" clearable chips item-title="code" label="Direction" item-value="id"
            v-model="event.departments" multiple persistent-hint>
            <!-- <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :subtitle="item.raw.code">
              </v-list-item>
            </template> -->
          </v-select>

          <v-select :items="flags" item-title="name" label="Drapeau" item-value="id" v-model="event.flag">
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" :subtitle="item.raw.code" append-icon="mdi-flag" :base-color="item.raw.color">
              </v-list-item>
            </template>
          </v-select>

          <!-- <small class="text-caption text-medium-emphasis">*indicates required field</small> -->

        </v-card-text>
        <v-card-footer>
          <v-col cols="auto">
            <v-btn block color="teal"
              v-show="readOnly == false"
              @click="save(event.id)">SAUVEGARDER</v-btn>
              
          </v-col>
        </v-card-footer>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import EventC from "@/components/EventC";
import axios from "axios";
import { getRandomColor, setRandomColor, flags } from "@/utils";
import { defineComponent } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
import moment from "moment";


export default defineComponent({
  components: {
    EventC,
    draggable: VueDraggableNext,
  },
  methods: {
    log(event) {
      var newFlag;
      if (event.added) {
        if (this.searchGreens.length > this.searchGreensLength) {
          this.searchGreensLength = this.searchGreens.length;
          newFlag = "green"
        } else if (this.searchReds.length > this.searchRedsLength) {
          newFlag = "red"
          this.searchRedsLength = this.searchReds.length;

        } else if (this.searchGreys.length > this.searchGreysLength) {
          newFlag = "grey"
          this.searchGreysLength = this.searchGreys.length;

        } else if (this.searchOranges.length > this.searchOrangesLength) {
          newFlag = "orange"
          this.searchOrangesLength = this.searchOranges.length;
        } else if (this.searchIndigos.length > this.searchIndigosLength) {
          newFlag = "indigo"
          this.searchIndigosLength = this.searchIndigos.length;
        }

        var self = this
        let id = event.added.element.id
        axios.get("https://roqserver.sophal.net/api/events/" + id).then((response) => {
          var element = response["data"];
          self.event.id = element.id
          self.event.title = element.subject
          self.event.description = element.description
          self.event.startDate = element.startDateTime
          self.event.endDate = element.endDateTime
          self.event.departmentEmitter = element.departmentEmitter
          self.event.departments = element.departments.map(e => e.id)
          self.event.flag = element.flag
          self.editMode = true;
          self.event.flag = setRandomColor(newFlag);
          self.save(id)
        })
      }
      // console.log("oranges",this.oranges.length)
      // console.log("greys",this.greys.length)
    },
    saveOrUpdate(id, dialog,readOnly) {
      this.dialog = dialog
      this.editMode = true
      this.readOnly = readOnly
      var self = this
      axios.get("https://roqserver.sophal.net/api/events/" + id).then((response) => {
        var element = response["data"];
        self.event.id = element.id
        self.event.title = element.subject
        self.event.description = element.description
        self.event.startDate = element.startDateTime
        self.event.endDate = element.endDateTime
        self.event.departmentEmitter = element.departmentEmitter
        self.event.departments = element.departments.map(e => e.id)
        self.event.flag = element.flag
        // self.editMode = true;
        self.dialog = dialog

      }).catch(err => {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Erreur!",
          footer: err
        });
      });
    },
    deleteEvent(id) {
      const self = this;
      this.$swal.fire({
        title: "Vous etes sur?",
        text: "Voulez vous supprimer cet element!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui"
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete("https://roqserver.sophal.net/api/events/delete/" + id).then(() => {
            this.$swal.fire({
              title: "Supprimé!",
              text: "Cet element a bien ete supprime.",
              icon: "success"
            });
            self.searchReds = self.searchReds.filter(e => e.id != id);
            self.searchGreens = self.searchGreens.filter(e => e.id != id);
            self.searchGreys = self.searchGreys.filter(e => e.id != id);
            self.searchOranges = self.searchOranges.filter(e => e.id != id);
            self.searchIndigosLength = self.searchIndigos.filter(e => e.id != id);
            // window.location.reload();
          }).catch(err => {
            this.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Erreur!",
              footer: err
            });
          });
        }
      });
    },
    saveMode() {
      this.resetEventFields();

      this.event.startDate = moment().format("YYYY-MM-DD hh:mm");
      this.event.endDate = moment().add(1, 'day').format("YYYY-MM-DD hh:mm");

      this.editMode = false;
      this.readOnly = false;
      this.dialog = true;
    },
    save(id) {
      var userId = this.$store.state.id;
      var body = {
        "subject": this.event.title,
        "typeSelect": "5",
        "userId": userId,
        "createdBy": { "id": userId },
        // "createdOn" : new Date().toLocaleString().slice(0,16),
        "startDateTime": this.event.startDate.split("T").join(" "),
        "endDateTime": this.event.endDate.split("T").join(" "),
        "description": this.event.description,
        "departmentEmitter": this.event.departmentEmitter == null ? null : { "id": this.event.departmentEmitter.id == null ? this.event.departmentEmitter : this.event.departmentEmitter.id },
        "departments": this.event.departments.map(e => ({ "id": e })),
        "flag": this.event.flag,
        "dtype": "Event"
      };

      var _self = this;
      if (id) {
        body["id"] = id;
      }
      body["departments"] = this.event.departments.map(e => ({ "id": e }));

      if (this.editMode == true) {
        axios.put("https://roqserver.sophal.net/api/events", body).then(() => {
          _self.dialog = false;
          _self.$swal
            .fire({
              title: "Changement!",
              text: "Changement reussi!",
              icon: "success"
            });
          this.editMode = false;
          // window.location.reload();

        }).catch(err => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Erreur!",
            footer: err
          });
        });
      } else {
        axios.post("https://roqserver.sophal.net/api/events", body).then((response) => {
          var resp = response["data"];
          resp["title"] = resp["subject"];
          resp["color"] = getRandomColor(resp["flag"]);

          this.dialog = false;
          this.editMode = false;

          console.log("depcode*********************************",resp["departments"],_self.depCode.toUpperCase());

          if (resp["departments"].filter(e => e.code.toUpperCase() == _self.depCode.toUpperCase())) {
            switch (resp["flag"]) {
              case 0:
                this.searchGreys.unshift(response["data"]); 
                break;
              case 1:
                this.searchReds.unshift(response["data"]);
                break;
              case 2:
                this.searchOranges.unshift(response["data"]);
                break;
              case 3:
                this.searchGreens.unshift(response["data"]);
                break;
              case 4:
                this.searchIndigos.unshift(response["data"]);
                break;
              default:
                this.searchGreys.unshift(response["data"]);
                break;
            }
          }

          // this.greens.push(response["data"]);

        }).catch(err => console.log(err));
      }
    },
    fetchDepartments() {
      var _self = this
      axios.get("https://roqserver.sophal.net/api/departments").then((response) => {
        response["data"].forEach(element => {
          _self.departments.push({
            id: element["id"],
            code: element["code"],
            name: element["name"],
          })
        });
        this.loading = false;
      });
    },
    searchChange() {
      if (this.search.length == 0) {
        this.searchGreens = this.greens;
        this.searchReds = this.reds;
        this.searchGreys = this.greys;
        this.searchOranges = this.oranges;
      }
      this.searchGreens = this.greens.filter(e => e.description.toUpperCase().includes(this.search.toUpperCase()));
      this.searchReds = this.reds.filter(e => e.description.toUpperCase().includes(this.search.toUpperCase()));
      this.searchGreys = this.greys.filter(e => e.description.toUpperCase().includes(this.search.toUpperCase()));
      this.searchOranges = this.oranges.filter(e => e.description.toUpperCase().includes(this.search.toUpperCase()));
      this.searchIndigos = this.indigos.filter(e => e.description.toUpperCase().includes(this.search.toUpperCase()));

      this.searchGreensLength = this.searchGreens.length
      this.searchRedsLength = this.searchReds.length
      this.searchGreysLength = this.searchGreys.length
      this.searchOrangesLength = this.searchOranges.length
      this.searchIndigosLength = this.searchIndigos.length
    },
    resetEventFields() {
      this.event.title = ""
      this.event.description = ""
      this.event.startDate = ""
      this.event.endDate = ""
      this.event.departments = []
      this.event.departmentId = ""
      this.event.flag = ""
      this.event.departmentEmitter = ""
    },
  },
  watch: {
    seach: function name(val, valo) {
      console.log(val, valo);
    }
  },
  mounted() {
    var _self = this;
    var depId = this.$route.params.departmentId
    var userId = this.$route.params.id
    this.depCode = this.$route.params.code
    var perCreated = this.$route.params.perCreated


    if (userId == 1 || userId == 242) {
      this.isDisabled = false
    }
    this.loading = true;

    let url = "https://roqserver.sophal.net/api/events/department/" + depId;

    console.log("perCreated == 1",perCreated == 1);

    if(perCreated == 1){
      url = "https://roqserver.sophal.net/api/dep/" + depId;
    }

    axios.get(url).then((response) => {
      console.log(response["data"]);
      var arr = []
      response["data"].forEach(element => {
        arr.push({
          id: element["id"],
          title: element["subject"],
          description: element["description"],
          start: element["startDateTime"],
          end: element["endDateTime"],
          departmentEmitter: element["departmentEmitter"],
          createdOn: element["createdOn"],
          departments: element["departments"] != null ? element["departments"] : "Not found",
          color: getRandomColor(element["flag"]),
          allDay: true
        })
        _self.actions = arr
      });
      _self.greens = arr.filter(e => e.color == "green")
      _self.reds = arr.filter(e => e.color == "red")
      _self.greys = arr.filter(e => e.color == "grey")
      _self.oranges = arr.filter(e => e.color == "orange")
      _self.indigos = arr.filter(e => e.color == "indigo")

      _self.searchGreens = _self.greens
      _self.searchReds = _self.reds
      _self.searchGreys = _self.greys
      _self.searchOranges = _self.oranges
      _self.searchIndigos = _self.indigos

      _self.searchGreensLength = _self.greens.length;
      _self.searchOrangesLength = _self.oranges.length;
      _self.searchRedsLength = _self.reds.length;
      _self.searchGreysLength = _self.greys.length;
      _self.searchIndigosLength = _self.indigos.length;

    });

    this.fetchDepartments()

  },
  data: () => ({
    dialog: false,
    loading: true,
    editMode  : false,
    readOnly  : false,
    depCode: "",
    search: "",
    flags: flags,
    isDisabled: true,
    event: {
      title: "",
      description: "",
      startDate: "",
      endDate: "",
      departments: [],
      departmentId: "",
      departmentEmitter: null,
      flag: "",
    },
    departments: [],
    actions: [],
    greens: [],
    reds: [],
    oranges: [],
    greys: [],
    searchIndigos: [],
    searchGreens: [],
    searchReds: [],
    searchOranges: [],
    searchGreys: [],
    indigoLength: 0,
    greensLength: 0,
    redsLength: 0,
    orangesLength: 0,
    greysLength: 0,
    texto: ""
  }),
  provide() {
    return {
      saveOrUpdate2: this.saveOrUpdate,
      deleteEvent2: this.deleteEvent,
    }
  }
});
</script>

<style scoped>
.list-group-item {
  border: 1px solid #00000050;
  padding: 15px;
  margin: 5px;
  border-radius: 2px;
  background: white;
}

.sortable-chosen {
  background: rgb(131, 131, 131);
  transform: rotate(2deg);
  color: white;
}

.rr {
  background: teal;
  color: white;
}

.v-card .v-card-text {
  height: 100%;
}

.w-full {
  height: 100%;
}

.clearfix {
  clear: both;
}
</style>