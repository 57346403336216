<template>
  <v-layout>
    <v-container grid-list-md fluid>
      <v-card color="pa-4">
        <my-calendar height="200"></my-calendar>
      </v-card>
    </v-container>
  </v-layout>
</template>

<script>
import MyCalendar from "@/components/MyCalendar.vue";

export default {
  components: { 
    MyCalendar, 
   },
   mounted(){
    this.$store.commit("setId", this.$route.params.id);
   }
};
</script>
