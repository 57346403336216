<template>
    <v-container fill-height fluid>
        <div v-if="loading" class="text-center">
            <v-progress-circular color="primary" indeterminate :size="49"></v-progress-circular>
        </div>
        <div v-else>
            <v-row v-if="actions.length > 0">
                <v-col md="4" sm="12" v-for="action in actions" :key="action.id">
                    <v-card :color="action.color" elevation="4" border="5" rounded outline>
                        <v-card-title primary-title class="text-white">
                            {{ action.title.toUpperCase() }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text color="white">
                            {{ action.description }}
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text class="right text-white">
                            <h3>
                                <span v-for="(dep, index) in action.departments" :key="dep.code">{{
            dep.code.toUpperCase() }}
                                    {{ (index < action.departments.length - 1) ? "; " : "" }} </span>
                            </h3>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions style="background : white" class="d-block">
                            <v-icon :color="action.color">mdi-flag</v-icon>
                            <div>
                                <br>
                                <span :class="`text-${action.color} pa-2`"><b>CREE LE :</b> {{ action.createdOn
                                    }}</span>
                                <br>
                                <span :class="`text-${action.color} pa-2`">-----------------------------------</span>
                                <br>
                                <span :class="`text-${action.color} pa-2`">
                                <b>CREE PAR :</b> {{action.departmentEmitter?.code }} -- {{ action.createdBy.name }}</span>
                                <br>
                                <span :class="`text-${action.color} pa-2`">-----------------------------------</span>
                            </div>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <div v-else class="text-center pa-10">
                Pas de données disponibles
            </div>
        </div>
    </v-container>
</template>

<script>

import { getRandomColor } from "../utils"
import axios from "axios";
import EventC from "@/components/EventC.vue";

export default {
    components: [EventC],
    data: () => ({
        actions: [],
        loading: false
    }),
    methods: {

    },
    mounted() {
        var _self = this;
        var userId = this.$route.params.id
        this.$store.commit("setId", this.$route.params.id);

        this.loading = true;
        axios.get("https://roqserver.sophal.net/api/events/user/" + userId).then((response) => {
            var arr = []
            console.log(response["data"]);
            if (response["data"].length == 0) {
                this.loading = false;
            }
            response["data"].forEach(element => {
                arr.push({
                    id: element["id"],
                    title: element["subject"],
                    description: element["description"],
                    start: element["startDateTime"],
                    end: element["endDateTime"],
                    createdOn: element["createdOn"],
                    createdBy: element["createdBy"],
                    departmentEmitter: element["departmentEmitter"],
                    departments: element["departments"] != null ? element["departments"] : "Not found",
                    color: getRandomColor(element["flag"]),
                    allDay: true
                })
                if (response["data"].length > 0) {
                    _self.actions = arr
                    this.loading = false;
                } else {
                    _self.actions = []
                    this.loading = false;
                }
                this.loading = false;

            });
        }).catch(err => {
            console.log(err);
            this.loading = false;
        });


    },
}
</script>

<style></style>