<template>
    <v-sheet class="ma-3" rounded border>
        <v-row class="pa-3">

            <v-col md="12">{{ now }}</v-col>

            <v-col md="3">
                <v-card color="red">
                    <v-card-title primary-title>

                        <v-list-item prepend-icon="mdi-flag" color="white" title="Rouge">
                            <template v-slot:append>
                                <v-badge color="error" :content="reds.length" inline></v-badge>
                            </template>
                        </v-list-item>
                        <v-icon color="white">mdi-plus</v-icon>
                    </v-card-title>
                    <v-card-text>

                        <draggable group="texto" class="dragArea list-group w-full" :list="reds" @change="log">
                            <div class="list-group-item bg-gray-300 m-1 p-3 rounded-md text-center cursor-grab"
                                style="color: red;" v-for="element in reds" :key="element.title">
                                {{ element.title }}
                            </div>
                        </draggable>

                    </v-card-text>
                </v-card>
            </v-col>

            <v-col md="3">
                <v-card color="grey">
                    <v-card-title primary-title>

                        <v-list-item prepend-icon="mdi-flag" color="white" title="Gris">
                            <template v-slot:append>
                                <v-badge color="grey" :content="greys.length" inline></v-badge>
                            </template>
                        </v-list-item>
                    </v-card-title>
                    <v-card-text>

                        <draggable group="texto" class="dragArea list-group w-full" :list="greys" @change="log">
                            <div class="list-group-item bg-gray-300 m-1 p-3 rounded-md text-center cursor-grab"
                                style="color: grey;" v-for="element in greys" :key="element.title">
                                {{ element.title }}
                            </div>
                        </draggable>

                    </v-card-text>
                </v-card>
            </v-col>

            <v-col md="3">
                <v-card color="orange">
                    <v-card-title primary-title>

                        <v-list-item prepend-icon="mdi-flag" color="white" title="Orange">
                            <template v-slot:append>
                                <v-badge color="orange" :content="oranges.length" inline></v-badge>
                            </template>
                        </v-list-item>
                    </v-card-title>
                    <v-card-text>

                        <draggable group="texto" class="dragArea list-group w-full" :list="oranges" @change="log">
                            <div class="list-group-item bg-gray-300 m-1 p-3 rounded-md text-center cursor-grab"
                                style="color: orange;" v-for="element in oranges" :key="element.title">
                                {{ element.title }}
                            </div>
                        </draggable>

                    </v-card-text>
                </v-card>
            </v-col>

            <v-col md="3">
                <v-card color="green">
                    <v-card-title primary-title>

                        <v-list-item prepend-icon="mdi-flag" color="white" title="Vert">
                            <template v-slot:append>
                                <v-badge color="green" :content="greens.length" inline></v-badge>
                            </template>
                        </v-list-item>
                    </v-card-title>
                    <v-card-text>

                        <draggable group="texto" class="dragArea list-group w-full" :list="greens" @change="log">
                            <div class="list-group-item bg-gray-300 m-1 p-3 rounded-md text-center cursor-grab"
                                style="color: grey;" v-for="element in greens" :key="element.title">
                                {{ element.title }}
                            </div>
                        </draggable>

                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>

    </v-sheet>
</template>
<script>
import { defineComponent } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
import { getRandomColor } from "../utils"
import axios from "axios";



export default defineComponent({
    components: {
        draggable: VueDraggableNext,
    },
    methods: {
        log(event) {
            console.log(event);
            console.log("greens", this.greens)
            console.log("reds", this.reds)
            // console.log("oranges",this.oranges.length)
            // console.log("greys",this.greys.length)
        },
    },
    data() {
        return {
            arr: [],
            loading: false,
            enabled: true,
            dragging: false,
            actions: [],
            greens: [],
            reds: [],
            oranges: [],
            greys: [],
        };
    },
    mounted() {
        this.loading = true;
        var _self = this;
        var userId = this.$route.params.id

        console.log("https://roqserver.sophal.net/api/events/user/" + userId);
        axios.get("https://roqserver.sophal.net/api/events/user/" + userId).then((response) => {
            var arr = []
            response["data"].forEach(element => {
                arr.push({
                    id: element["id"],
                    title: element["subject"],
                    description: element["description"],
                    start: element["startDateTime"],
                    end: element["endDateTime"],
                    createdOn: element["createdOn"],
                    departmentEmitter: element["departmentEmitter"],
                    departments: element["departments"] != null ? element["departments"] : "Not found",
                    color: getRandomColor(element["flag"]),
                    allDay: true
                })
                _self.greens.push(element);
                if (response["data"].length > 0) {
                    _self.actions = arr
                    _self.greens = arr.filter(e => e.color == "green")
                    _self.reds = arr.filter(e => e.color == "red")
                    _self.greys = arr.filter(e => e.color == "grey")
                    _self.oranges = arr.filter(e => e.color == "orange")
                    this.loading = false;
                } else {
                    _self.actions = []
                }
            });
        });

    }
});
</script>


<style scoped>
.list-group-item {
    border: 1px solid #00000050;
    padding: 15px;
    margin: 5px;
    border-radius: 2px;
    background: white;
}

.sortable-chosen {
    background: rgb(131, 131, 131);
    transform: rotate(2deg);
    color: white;
}

.rr {
    background: teal;
    color: white;
}
</style>